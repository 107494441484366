import React from 'react';
import PropTypes from 'prop-types';

import {PageWrapper} from '../components/templates';
import LayoutGenerator from '../helpers/layoutGenerator';

/**
 * @description Page template for 'about' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} About page React element.
 */
const QuantInsight = ({pageContext}) => {
  return (
    <PageWrapper pageContext={pageContext}>
      <LayoutGenerator layouts={pageContext.layoutContainer} />
    </PageWrapper>
  );
};

QuantInsight.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default QuantInsight;
